<template>
    <div>
        <st-modal
            id="st-modal-application-type"
            hide-header-delimiter
            hide-footer-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hideable
            size="xl"
            :title="headerText"
            customClass="form-modal"
            ref="modal"
        >
            <template #body>
                <application-type-form
                    ref="application-type-form"
                    :editMode="editMode"
                    @cancelForm="hide"
                >
                </application-type-form>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button size="large" variant="link" :callback="hide">
                        {{ $t("GENERAL.BUTTON.CANCEL") }}
                    </st-button>
                    <st-button
                        size="large"
                        variant="primary"
                        :callback="doSubmit">
                        <span>{{ $t("GENERAL.BUTTON.SAVE") }}</span>
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import { mapActions} from "vuex";
import ApplicationTypeForm from './ApplicationTypeForm.vue';
export default {
    name: "ApplicationTypeFormModal",
    components: {
        ApplicationTypeForm
    },
    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        headerText() {
            return this.editMode
                ? this.$t("APPLICATION_TYPE.FORM.EDIT_TITLE")
                : this.$t("APPLICATION_TYPE.FORM.ADD_TITLE");
        },
    },
    methods: {
        ...mapActions({
            unselectRecord: 'applicationType/form/unselect',
        }),
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.unselectRecord();
            this.$refs['modal'].hide();
        },
        doSubmit() {
            this.$refs['application-type-form'].doSubmit();
        },
    },
};
</script>
