<template>
    <st-page
        :title="$t('APPLICATION_TYPE.LIST.TITLE')"
    >
        <loader v-if="isLoading"/>
        <template #toolbar>
            <application-type-list-toolbar ref="application-type-list-toolbar"/>
        </template>
        <application-type-list-filter>
            <application-type-list-table @editApplication="editApplication"/>
        </application-type-list-filter>
    </st-page>
</template>

<script>
    import ApplicationTypeListFilter from '../components/ApplicationTypeListFilter';
    import ApplicationTypeListTable from '../components/ApplicationTypeListTable';
    import ApplicationTypeListToolbar from '../components/ApplicationTypeListToolbar';
    import { mapGetters } from "vuex";

    export default {
        name: 'ApplicationTypeList',
        components: {
            ApplicationTypeListFilter,
            ApplicationTypeListTable,
            ApplicationTypeListToolbar,
        },
        computed: {
            ...mapGetters({
                loading: 'shared/loading',
            }),
            loadingFetch() {
                return this.loading['applicationType/get'];
            },
            isLoading() {
                return this.loadingFetch;
            },
        },
        methods: {
            editApplication() {
                this.$refs['application-type-list-toolbar'].editApplicationType();
            }
        }
    }
</script>
