import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import EnumField from '@/shared/fields/enum-field';
import IdField from '@/shared/fields/id-field';
import { FormsField } from '@/modules/forms/forms-field';
import { DocumentTemplateField } from '@/modules/document-template/document-template-field';
import { DocumentSeriesTypeField } from '@/modules/document-series-type/document-series-type-field';
import { TaxCategoryField } from '@/modules/tax-category/tax-category-field';
import { ApplicationTypeField } from '@/modules/application-type/application-type-field';
import { WorkflowsField } from '@/modules/application-type/workflows-field';
import { OrganizationField } from '@/modules/administrator/fields/organisations-field';
import { DiscountsField } from '@/modules/application-type/discounts-field';
import IntegerField from '@/shared/fields/integer-field';
import BooleanField from '@/shared/fields/boolean-field';
import { TerritorialAdministrativeUnitField } from '@/shared/common-fields/territorial-administrative-unit-field';
import { DepartmentsField } from '@/modules/administrator/fields/departments-field';
import { FunctionsField } from '@/modules/administrator/fields/functions-field';

const label = (name) => i18n.t(`APPLICATION_TYPE.FIELDS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`APPLICATION_TYPE.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);
const placeholder = (name) => i18n.t(`APPLICATION_TYPE.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    name: new StringField('name', label('name'), {
        required: true,
    }, { placeholder: placeholder('name') }),
    description: new StringField('description', label('description')),
    status: new EnumField('status', label('status'), [
        { value: 'active', text: enumLabel('status', 'active') },
        { value: 'inactive', text: enumLabel('status', 'inactive') }
    ], {
        required: true
    }, { placeholder: placeholder('status') }),
    form_citizen_id: FormsField.relationToOne(
        'form_citizen_id',
        label('form_citizen'),
        { autocompleteRequired: true },
    ),
    form_staff_id: FormsField.relationToOne(
        'form_staff_id',
        label('form_staff'),
        { autocompleteRequired: true },
    ),
    reject_form_staff_id: FormsField.relationToOne(
        'reject_form_staff_id',
        label('reject_form_staff'),
        { autocompleteRequired: true },
    ),
    document_template_id: DocumentTemplateField.relationToOne(
        'document_template_id',
        label('document_template'),
    ),
    application_document_template_id: DocumentTemplateField.relationToOne(
        'application_document_template_id',
        label('application_document_template'),
    ),
    reject_document_template_id: DocumentTemplateField.relationToOne(
        'reject_document_template_id',
        label('reject_document_template'),
    ),
    review_document_template_id: DocumentTemplateField.relationToOne(
        'review_document_template_id',
        label('review_document_template'),
    ),
    public_document_template_id: DocumentTemplateField.relationToOne(
        'public_document_template_id',
        label('public_document_template'),
    ),
    document_series_type_id: DocumentSeriesTypeField.relationToOne(
        'document_series_type_id',
        label('document_series_type'),
        { autocompleteRequired: true },
    ),
    application_document_series_type_id: DocumentSeriesTypeField.relationToOne(
        'application_document_series_type_id',
        label('application_document_series_type'),
        { autocompleteRequired: true },
    ),
    tax_category_id: TaxCategoryField.relationToOne(
        'tax_category_id',
        label('tax_category'),
        { required: true },
    ),
    land_tax_category_id: TaxCategoryField.relationToOne(
        'land_tax_category_id',
        label('land_tax_category'),
        { required: true },
    ),
    construction_tax_category_id: TaxCategoryField.relationToOne(
        'construction_tax_category_id',
        label('construction_tax_category'),
        { required: true },
    ),
    extra_tax_category_id: TaxCategoryField.relationToOne(
        'extra_tax_category_id',
        label('extra_tax_category'),
        { required: false },
    ),
    is_tax_category_conditional: new BooleanField(
        'is_tax_category_conditional',
        label('is_tax_category_conditional'),
    ),
    related_application_type_ids: ApplicationTypeField.relationToOne(
        'related_application_type_ids',
        label('application_type_ref'),
        {},
    ),
    workflow_id: WorkflowsField.relationToOne(
        'workflow_id',
        label('workflow'),
        { autocompleteRequired: true },
    ),
    rural_percentage_discount: new IntegerField(
        'rural_percentage_discount',
        '',
        { required: true, min: 1, max: 100 },
    ),
    archive_document_type_code: new StringField(
        'archive_document_type_code',
        label('archive_document_type_code'), { required: true }
    ),
    tenant_id: OrganizationField.relationToOne(
        'tenant_id',
        label('tenant_id'),
        {},
        { placeholder: placeholder('tenant_id') },
    ),
    is_auto: new BooleanField('is_auto', label('is_auto')),
    remaining_period: new IntegerField(
        'remaining_period',
        label('remaining_period'),
        { required: true },
    ),
    order: new IntegerField(
        'order',
        label('order'),
        {},
    ),
    discounts: DiscountsField.relationToOne(
        'discounts',
        label('discounts'),
        {},
    ),
    uats: TerritorialAdministrativeUnitField.relationToOne(
        'territorial_administrative_units',
        label('uats'),
        {},
    ),
    is_internal: new BooleanField('is_internal', label('is_internal')),
    has_digital_seal: new BooleanField('has_digital_seal', label('has_digital_seal')),
    is_tax_fixed: new BooleanField('is_tax_fixed', label('is_tax_fixed')),
    review_application_type_id: ApplicationTypeField.relationToOne(
        'review_application_type_id',
        label('review_application_type_id'),
        {},
    ),
    internal_application_type_id: ApplicationTypeField.relationToOne(
        'internal_application_type_id',
        label('internal_application_type_id'),
        {},
    ),
    departments: DepartmentsField.relationToOne(
        'departments',
        label('departments'),
        { autocompleteRequired: false, firstLetterFilter: true  },
        { placeholder: placeholder('departments') },
        [{
            key: 'org_id',
            name: 'tenant_id'
        }],
        { asyncData: true }
    ),
    functions: FunctionsField.relationToOne(
        'functions',
        label('functions'),
        { },
        { placeholder: placeholder('functions') },
    )
};

export class ApplicationTypeModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
