import RelationToOneField from '@/shared/fields/relation-to-one-field';
import { TaxCategoryService } from '@/modules/tax-category/tax-category-service';

export class TaxCategoryField {
    static relationToOne(name, label, options) {
        return new RelationToOneField(
            name,
            label,
            TaxCategoryService.listAutocomplete,
            (record) => {
                if (!record) {
                    return null;
                }

                return {
                    value: record.id,
                    text: record.name,
                };
            },
            options,
        );
    }
}
