<template>
    <div>
        <st-button
            variant="secondary"
            :callback="addApplicationType"
        >
            <i class="fa fa-plus"></i>
            {{ $t('APPLICATION_TYPE.ADD_BUTTON') }}
        </st-button>
        <application-type-form-modal
            ref="applicationTypeFormModal"
            :edit-mode="editMode"
        ></application-type-form-modal>
    </div>
</template>

<script>
    import ApplicationTypeFormModal from '@/modules/application-type/components/ApplicationTypeFormModal.vue';

    export default {
        name: 'ApplicationTypeListToolbar',
        components: { ApplicationTypeFormModal },
        data() {
            return {
                editMode: false,
            };
        },
        methods: {
            addApplicationType() {
                this.editMode = false;
                this.$refs.applicationTypeFormModal.show();
            },
            editApplicationType() {
                this.editMode = true;
                this.$refs.applicationTypeFormModal.show();
            }
        }
    };
</script>

