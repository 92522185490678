import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';

export class DiscountsField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        return new RelationToOneField(
            name,
            label,
            () => {
                return new Promise((resolve) => {
                    ApiService.query('discounts?limit=100')
                        .then(({ data }) => {
                            resolve(data.data);
                        });
                })
            },
            (record) => {
                if (!record) {
                    return '';
                }

                return {
                    value: record.id,
                    text: record.name,
                    description: record.description,
                }
            },
            rules,
            filterParams,
            children,
            options
        )
    }
}