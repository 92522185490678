import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';

export class WorkflowsField {
    static relationToOne(name, label, options) {
        return new RelationToOneField(
            name,
            label,
            () => {
                return new Promise((resolve) => {
                    ApiService.query('workflows?query=status eq published&limit=200')
                        .then(({ data }) => {
                            resolve(data.data);
                        });
                })
            },
            (record) => {
                if (!record) {
                    return '';
                }

                return {
                    value: record.id,
                    text: record.name,
                }
            },
            options,
        )
    }
}
